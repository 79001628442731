/**Created by liaoyingchao on 11/23/22.*/

<template>
  <div class="index page-css">
    <div class="block-div index-overview">
      <div class="block-title">平台概况</div>
      <div class="items-div">
        <div class="item-div">
          <div class="item-label single-row">昨日销售总额</div>
          <div class="item-value single-row">
            <span class="number">{{ dataObj.salesAmount }}</span>
            <span class="unit">元</span>
          </div>
        </div>
        <div class="item-div">
          <div class="item-label single-row">昨日订单</div>
          <div class="item-value single-row">
            <span class="number">{{ dataObj.totalOrder }}</span>
            <span class="unit">笔</span>
          </div>
        </div>
        <div class="item-div">
          <div class="item-label single-row">昨日平台访问</div>
          <div class="item-value single-row">
            <span class="number">{{ dataObj.rfFrequency }}</span>
            <span class="unit">次</span>
          </div>
        </div>
        <div class="item-div">
          <div class="item-label single-row">昨日平台访客</div>
          <div class="item-value single-row">
            <span class="number">{{ dataObj.rfPeople }}</span>
            <span class="unit">人</span>
          </div>
        </div>
        <div class="item-div">
          <div class="item-label single-row">待审核退款</div>
          <div class="item-value single-row">
            <span class="number">{{ dataObj.refund }}</span>
            <span class="unit">笔</span>
          </div>
        </div>
        <div class="item-div">
          <div class="item-label single-row">待发货订单</div>
          <div class="item-value single-row">
            <span class="number">{{ dataObj.paid }}</span>
            <span class="unit">笔</span>
          </div>
        </div>
      </div>
    </div>
    <div class="charts-div">
      <div class="block-div">
        <div class="block-title">平台日销售趋势</div>
        <div class="chart-cont" id="dayTrend"></div>
      </div>
      <div class="block-div">
        <div class="block-title">平台月销售趋势</div>
        <div class="chart-cont" id="monthTrend"></div>
      </div>
      <div class="block-div">
        <div class="block-title">平台日活趋势</div>
        <div class="chart-cont" id="dailyActive"></div>
      </div>
      <div class="block-div">
        <div class="block-title">平台3天内热销商品排行榜</div>
        <div class="chart-cont" id="ranking"></div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { onMounted, ref } from "vue";
import homeApi from "@/api/home";
import * as echarts from "echarts/core";
import {
  TitleComponent,
  ToolboxComponent,
  TooltipComponent,
  GridComponent,
  LegendComponent,
} from "echarts/components";
import { ElMessage } from "element-plus";
import { BarChart, LineChart } from "echarts/charts";
import { UniversalTransition } from "echarts/features";
import { CanvasRenderer } from "echarts/renderers";

echarts.use([
  TitleComponent,
  ToolboxComponent,
  TooltipComponent,
  GridComponent,
  LegendComponent,
  BarChart,
  LineChart,
  CanvasRenderer,
  UniversalTransition,
]);
const dataObj = ref({
  paid: 0,
  refund: 0,
  rfPeople: 0,
  totalOrder: 0,
  rfFrequency: 0,
  salesAmount: 0,
});

const monthTrendList = ref([])
const dayTrendList = ref([])
const dailyActiveList = ref([])
const rankingList = ref([])
const rankingTitleList = ref([])
function rankingFunc(arr, titleArr) {
  var chartDom = document.getElementById("ranking");
  if (chartDom == null) {
    return;
  }
  echarts.dispose(chartDom);
  var myChart = echarts.init(chartDom);
  let option = {
    tooltip: {
    trigger: 'axis',
    formatter:function(params){
						return params[0].name  +'<br>订单数 '+ params[0].data.value + '<br>订单金额  ' + params[0].data.psSale;
					}
  },
    grid: {
      show: false,
      top: "10%",
      right: "5%",
      bottom: "10%",
      left: "10%",
    },
    legend: {
      data: ["Evaporation", "Temperature"],
      orient: "horizontal", //可设定图例水平（horizontal）排列还是竖直排列
      // x: "center", //可设定图例在左、右、居中
      y: "bottom",
    },
    xAxis: {
      type: "value",
      boundaryGap: [0, 0.01],
    },
    yAxis: {
      type: "category",
      data: titleArr,
    },
    series: [
      {
        name: "",
        type: "bar",
        data: arr,
        barWidth: "15",
        itemStyle: {
          normal: {
            barBorderRadius: [0, 30, 30, 0],
            color: new echarts.graphic.LinearGradient(
              0,
              1,
              0,
              0,
              [
                {
                  offset: 0,
                  color: "#CADBFF", // 0% 处的颜色
                },
                {
                  offset: 1,
                  color: "#4D84FD", // 100% 处的颜色
                },
              ],
              false
            ),
          },
        },
      },
    ],
  };
  myChart.setOption(option, true);
  option && myChart.setOption(option);
}

const dataHandle = (id, data) => {
  let arr = [
    {
      name: "成交金额(元)",
      type: "bar",
      data: [],
      barWidth: "15",
      itemStyle: {
        normal: {
          barBorderRadius: [30, 30, 0, 0],
          color: new echarts.graphic.LinearGradient(
            0,
            1,
            0,
            0,
            [
              {
                offset: 0,
                color: "#CADBFF", // 0% 处的颜色
              },
              {
                offset: 1,
                color: "#4D84FD", // 100% 处的颜色
              },
            ],
            false
          ),
        },
      },
    },
    {
      name: "订单数量(次)",
      type: "line",
      yAxisIndex: 1,
      data: [],
      lineStyle: {
        color: "#F3B151",
      },
    },
  ];
  let titleArr = [];
  if (id == "dayTrend") {
    for (let i = 0; i < data.length; i++) {
      arr[0].data.push(data[i].salesAmount);
      arr[1].data.push(data[i].totalOrder);
      titleArr.push(`${data[i].rfDay}日`);
    }
    arr[0].data = arr[0].data.reverse()
  arr[1].data = arr[1].data.reverse()
  }else if(id == "monthTrend"){
    for (let i = 0; i < data.length; i++) {
      arr[0].data.push(data[i].totalAmount);
      arr[1].data.push(data[i].totalOrder);
      titleArr.push(`${data[i].rfMonth}月`);
    }
    arr[0].data = arr[0].data.reverse()
  arr[1].data = arr[1].data.reverse()
  }else{
    arr = [arr[1]]
    arr[0].name = '平台访问次数(次)'
    for (let i = 0; i < data.length; i++) {
      arr[0].data.push(data[i].rfFrequency);
      titleArr.push(`${data[i].rfDay}日`);
    }
    arr[0].data = arr[0].data.reverse()
  }
  return {
    arr: arr,
    titleArr: titleArr.reverse(),
  };
};

function dayTrendFunc(id, data) {
  let yAxis = [
    {
      type: "value",
      name: "金额(元)",
      axisLabel: {
        formatter: "{value}",
      },
      splitLine: {
        show: false,
      },
    },
    {
      type: "value",
      name: "订单量",
      axisLabel: {
        formatter: "{value}",
      },
      splitLine: {
        show: false,
      },
    },
  ];
  var chartDom = document.getElementById(id);
  if (chartDom == null) {
    return;
  }
  echarts.dispose(chartDom);
  var myChart = echarts.init(chartDom);

  let title = "";
  if (id == "dailyActive") {
    yAxis = [
    {
        show: false,
      },
      {
        type: "value",
        name: "平台访问次数",
        axisLabel: {
          formatter: "{value}",
        },
      },

    ];
  }
  let option = {
    tooltip: {
    trigger: 'axis',
  },
    grid: {
      show: false,
      top: "15%",
      right: "8%",
      bottom: "20%",
      left: "10%",
    },
    legend: {
      data: ["平台访问次数(次)", "成交金额(元)", "订单数量(次)"],
      orient: "horizontal", //可设定图例水平（horizontal）排列还是竖直排列
      // x: "center", //可设定图例在左、右、居中
      y: "bottom",
    },
    xAxis: [
      {
        type: "category",
        data: dataHandle(id, data).titleArr,
        axisPointer: {
          type: "shadow",
        },
      },
    ],
    yAxis: yAxis,
    series: dataHandle(id, data).arr,
  };
  myChart.setOption(option, true);
  option && myChart.setOption(option);
}


function getData() {
  homeApi.survey().then((res) => {
    if (res.code == 0) {
      dataObj.value = res.data;
      console.log(dataObj);
    } else {
      ElMessage.error(res.msg);
    }
  });
}
function monthsaleFunc() {
  homeApi.monthsale().then((res) => {
    if (res.code == 0) {
      monthTrendList.value = res.data
      dayTrendFunc("monthTrend", res.data);
    } else {
      ElMessage.error(res.msg);
    }
  });
}
function daysaleFunc() {
  homeApi.daysale().then((res) => {
    if (res.code == 0) {
      dayTrendList.value = res.data
      dayTrendFunc("dayTrend", res.data);
    } else {
      ElMessage.error(res.msg);
    }
  });
}
function dayliveFunc() {
  homeApi.daylive().then((res) => {
    if (res.code == 0) {
      dailyActiveList.value = res.data
      dayTrendFunc("dailyActive", res.data);
    } else {
      ElMessage.error(res.msg);
    }
  });
}
function hostsaleFunc() {
  homeApi.hostsale().then((res) => {
    if (res.code == 0) {
      let arr = []
      let titleArr = []
      for (let i = 0; i < res.data.length; i++) {
         arr.push({
          psSale: res.data[i].psSale,
          value:res.data[i].cartNumber})
         titleArr.push(res.data[i].productName)
      }
      rankingList.value = arr.reverse()
      rankingTitleList.value = titleArr.reverse()
      rankingFunc(arr, titleArr);
    } else {
      ElMessage.error(res.msg);
    }
  });
}
window.onresize = () => {
  var monthTrend = echarts.init(document.getElementById('monthTrend'));
  var dayTrend = echarts.init(document.getElementById('dayTrend'));
  var dailyActive = echarts.init(document.getElementById('dailyActive'));
  var ranking = echarts.init(document.getElementById('ranking'));
  monthTrend.setOption({}, true);
  dayTrend.setOption({}, true);
  ranking.setOption({}, true);
  dailyActive.setOption({}, true);
  dayTrendFunc("monthTrend", monthTrendList._rawValue)
  dayTrendFunc("dayTrend", dayTrendList._rawValue)
  dayTrendFunc("dailyActive", dailyActiveList._rawValue)
  rankingFunc(rankingList._rawValue, rankingTitleList._rawValue);
}
onMounted(() => {
  getData();
  monthsaleFunc();
  daysaleFunc();
  dayliveFunc();
  hostsaleFunc()
});
</script>

<style lang="stylus" scoped type="text/stylus">
.index {
  width: 100%;
  height: 100%;
  padding: 10px;

  // 块的样式，用同一个保证一致性
  .block-div {
    border: 1px solid #ddd;
    padding: 20px;

    .block-title {
      border-left: 3px solid #6e95ee;
      font-size: 18px;
      font-weight: 600;
      line-height: 1;
      padding-left: 8px;
    }
  }

  .index-overview {
    .items-div {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-top: 20px;
      text-align: center;
      line-height: 1;

      .item-div {
        .item-label {
          font-size: 16px;
        }

        .item-value {
          padding-top: 12px;
          display: flex;
          align-items: flex-end;
          justify-content: center;

          .number {
            font-size: 20px;
            font-weight: 600;
          }

          .unit {
            margin-left: 5px;
            margin-bottom: 2px;
            font-size: 12px;
          }
        }
      }
    }
  }

  .charts-div {
    height: calc(100% - 138px);
    margin-top: 10px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    gap: 10px;
    overflow hidden;
    .block-div {
      overflow hidden;
    }

    .chart-cont {
      height: calc(100% - 18px);
    }
  }

  .index-top-div {
    // box-shadow: 0 0 5px 5px #fafafa;
    height: 20%;

    .index-top-div-title {
      padding: 10px;
      font-size: 18px;
      font-weight: 600;
    }

    .index-top-div-data {
      display: flex;
      justify-content: center;
      padding: 20px;
      color: #333333;

      .index-top-div-data-div {
        flex: 1;
        text-align: center;

        .index-top-div-data-div-title {
          font-size: 16px;
          padding: 5px 0;
        }

        .index-top-div-data-div-num {
          font-size: 18px;
          padding: 5px 0;
        }
      }
    }
  }
}
</style>