import http from "@/utils/http";

const home = {
  survey(data) {
    return http.post("/api/home/survey", data);
  },
  daysale(data) {
    return http.post("/api/home/daysale", data);
  },
  monthsale(data) {
    return http.post("/api/home/monthsale", data);
  },
  daylive(data) {
    return http.post("/api/home/daylive", data);
  },
  hostsale(data) {
    return http.post("/api/home/hostsale", data);
  },
};

export default home;
